import styled, { css } from 'styled-components';
import Img from 'gatsby-image';
import { fonts } from '../../../../../globals';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`;

export const WrapperBackground = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
`;

export const WrapperText = styled.div`
  ${{ ...fonts.popup }}
  font-weight: 700;
  text-align: center;
  padding: 1rem 1.75rem;
  background: rgba(0, 0, 0, 0.7);
  color: ${({ colorText }) => colorText};
  width: ${({ widthText }) => `${widthText}%`};
  margin-bottom: 5rem;
  opacity: 0;
  transform: translate3d(0,40%,0);
  transition: all 0.8s cubic-bezier(0.78, 0, 0.235, 1) 200ms;
  ${({ visible }) => visible
    && css`
        opacity: 1;
        transform: translate3d(0,0,0);
      `};
  margin-left: ${({ fullScreen }) => fullScreen && '6.6%'};
`;

export const ObserverRef = styled.div`
    width: ${({ observer }) => observer};
    height: 50vh;
    opacity:0;
`;
