/* eslint-disable no-unused-expressions */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
import React from 'react';
import TextAnimation from 'src/components/mobile/textAnimation/textAnimation';
import BackgroundPhoto from 'src/components/mobile/backgroundPhoto/backgroundPhoto';
import { BlockContainer } from '../../_styles';

const SubSlide = ({ query }) => (
  <BlockContainer height="100vh">
    <BackgroundPhoto
      fixed={query.file.childImageSharp.fixed}
      scale
      alt="slide-photo"
    >
      <div style={{ height: '80%',overflowY: 'auto' }}>
        <TextAnimation body={query.mdx.body} containerStyle={{height: 'auto'}}/>
      </div>
    </BackgroundPhoto>
  </BlockContainer>
);

export default SubSlide;
