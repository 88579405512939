import React from 'react';
import LeftColContent from 'src/components/desktop/leftColContent/leftColContent';
import WarGlobalRangeAnimation from 'src/components/desktop/warGlobalRangeAnimation/WarGlobalRangeAnimation';
import { graphql, useStaticQuery } from 'gatsby';
import { NoiseOverlayLight } from 'src/components/desktop/noiseOverlay/noiseOverlay';
import { SlideXLarge } from '../../_styles';

const Slide = ({ query }) => {
  const assetQuery = useStaticQuery(graphql`
  query {
    background: file(relativePath: { eq: "chapter_1/sub_1/map/map_background.png"}) {
      childImageSharp {
        fluid(maxWidth: 1024, maxHeight: 593) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
  },
    border: file(relativePath: { eq: "chapter_1/sub_1/map/map_border.png"}) {
      childImageSharp {
        fluid(maxWidth: 1024, maxHeight: 593) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
    color: file(relativePath: { eq: "chapter_1/sub_1/map/map_color.png"}) {
      childImageSharp {
        fluid(maxWidth: 1024, maxHeight: 593) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  }
  }
  `);

  return (
    <>
      <NoiseOverlayLight />
      <SlideXLarge style={{ background: '#071019' }}>

        <LeftColContent style={{ paddingLeft: '10vw' }} body={query.content.edges[0].node.body} paragraphWidth={20} smallerText />
        <WarGlobalRangeAnimation
          body={query.animation.edges[0].node.body}
          query={assetQuery}
        />
      </SlideXLarge>
    </>
  );
};


export default Slide;
