import React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import BackgroundImage from 'gatsby-background-image';
import { useInView } from 'react-intersection-observer';

import { Wrapper, Header, Content, ColLeft, ColRight } from './styles';

const TwoColContent = ({ photo, body, alt }) => {

  const [ref, inView] = useInView({
    threshold: 0.8,
  });

  return (

    <BackgroundImage fluid={photo} style={{ height: '100%', width: '100%' }}>
      <Wrapper visible={inView} ref={ref} >
        <MDXProvider components={{
          p: (props) => <p {...props} tabIndex="0" />,
        }}
        >
          <Header visible={inView} >
            <MDXRenderer>{body}</MDXRenderer>
          </Header>
          <Content visible={inView} >
            <ColLeft>
              <MDXRenderer>{body}</MDXRenderer>
            </ColLeft>
            <ColRight>
              <MDXRenderer>{body}</MDXRenderer>
            </ColRight>
          </Content>
        </MDXProvider>
      </Wrapper>
    </BackgroundImage>

  );
};

export default TwoColContent;
