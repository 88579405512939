import styled, { css } from 'styled-components';
import { fonts, colors } from '../../../../../globals';

export const Wrapper = styled.div`
  position: relative;
  width: 50%;
  /* height: 100%; */
  
`;
// added width 0 to animate border because it was leaving white mark without it
export const Image = styled.img`
  width: 0;
  opacity: 0;
  ${({ visible }) => visible
    && css`
      opacity: 1;
      width: 100%;
    `};
`;

export const BackgroundImg = styled(Image)`
  transition: opacity 2s;
  margin-bottom: 2.6rem;
`;

export const BorderImg = styled(Image)`
  transition: opacity 1.5s 0.1s, width 0s 0.5s;
  position: absolute;
  top: 0;
  left: 0;
`;
export const ColorImg = styled(Image)`
  transition: opacity 1.5s 1.2s;
  position: absolute;
  top: 0;
  left: 0;
`;

export const Legend = styled.div`
  display: flex;
  justify-content: center;
`;

export const LegendContainer = styled.div`
  margin-right: 4.18rem;
`;

export const LegendWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.68rem;
`;

export const LegendLabel = styled.div`
  ${{ ...fonts.popup }}
  ${{ ...colors.light }}
  font-size: 0.625rem;
  opacity: 0;
  transition: all 2s 1s;
  ${({ visible }) => visible
    && css`
      opacity: 1;
      width: 100%;
    `};
`;

export const LabelColor = styled.div`
  width: 39px;
  height: 13px;
  margin-right: 1rem;
`;

export const LabelShape = styled.div`
  width: 0;
  height: 100%;
  opacity: 0;
  transition: opacity 1s cubic-bezier(0.82, 0, 0.195, 1) 1s,
    width 1.7s cubic-bezier(0.82, 0, 0.195, 1) 1s;
  ${({ visible }) => visible
    && css`
      opacity: 1;
      width: 100%;
    `};
`;

export const LabelCentral = styled(LabelShape)`
  background: #d6ab48;
`;
export const LabelGerman = styled(LabelShape)`
  background: #ecdbb6;
`;
export const LabelNeutral = styled(LabelShape)`
  background: #fff;
`;
export const LabelEntenty = styled(LabelShape)`
  background: #777772;
`;
export const LabelEntentyAllies = styled(LabelShape)`
  background: #cbcac8;
`;
export const LabelDominia = styled(LabelShape)`
  background: #e2efe6;
`;
