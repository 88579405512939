import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const Bullet = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 20rem;
    position: relative;

    &:after{
        content: '';
        display: block;
        height: 100%;
        width: 1px;
        background: #c3bdb8;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }
`;

export const BulletInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    color: #484440;
    font-family: "Red Hat Display";
    font-size: 0.875rem;
    width: 9rem;
    text-align: right;
    padding: 0.8rem 0;
`;

export const BulletName = styled.p`
    font-weight: 700;
`;

export const BulletCaliber = styled.p`
    font-weight: 400;
`;

export const BulletImgContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 9rem;
    padding: 0.8rem 0;
`;

export const BulletImg = styled.img`

`;
