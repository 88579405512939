import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import SignedPhoto from 'src/components/desktop/signedPhoto/signedPhoto.jsx';
import { SlideMedium } from '../../_styles';


const Slide = ({ query }) => {
  const assetQuery = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "chapter_1/sub_1/4.png" }) {
        childImageSharp {
          fixed(width: 1484, height: 1080) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
            width
          }
        }
      }
    }
  `);
  return (
    <SlideMedium>
      <SignedPhoto
        photo={assetQuery.file.childImageSharp.fixed}
        text={query.mdx.body}
        colorText="#fff"
        widthText={45}
        alt={query.mdx.frontmatter.alt}
      />
    </SlideMedium>
  );
};

export default Slide;
