import React from 'react';
import bullet1 from 'src/assets/images/chapter_4/sub_7/mobile/bullet_1.png';
import bullet2 from 'src/assets/images/chapter_4/sub_7/mobile/bullet_2.png';
import bullet3 from 'src/assets/images/chapter_4/sub_7/mobile/bullet_3.png';
import bullet4 from 'src/assets/images/chapter_4/sub_7/mobile/bullet_4.png';
import bullet5 from 'src/assets/images/chapter_4/sub_7/mobile/bullet_5.png';
import {
  Container, Bullet, BulletInfo, BulletName, BulletCaliber, BulletImg, BulletImgContainer,
} from './styles';


const BulletsInfo = ({ bullets }) => (
  <Container>
    <Bullet>
      <BulletInfo>
        <BulletName>{bullets[0].name}</BulletName>
        <BulletCaliber>{bullets[0].caliber}</BulletCaliber>
      </BulletInfo>
      <BulletImgContainer>
        <BulletImg src={bullet1} alt="bullet-1" />
      </BulletImgContainer>
    </Bullet>
    <Bullet>
      <BulletInfo>
        <BulletName>{bullets[1].name}</BulletName>
        <BulletCaliber>{bullets[1].caliber}</BulletCaliber>
      </BulletInfo>
      <BulletImgContainer>
        <BulletImg src={bullet2} alt="bullet-2" />
      </BulletImgContainer>
    </Bullet>
    <Bullet>
      <BulletInfo>
        <BulletName>{bullets[2].name}</BulletName>
        <BulletCaliber>{bullets[2].caliber}</BulletCaliber>
      </BulletInfo>
      <BulletImgContainer>
        <BulletImg src={bullet3} alt="bullet-3" />
      </BulletImgContainer>
    </Bullet>
    <Bullet>
      <BulletInfo>
        <BulletName>{bullets[3].name}</BulletName>
        <BulletCaliber>{bullets[3].caliber}</BulletCaliber>
      </BulletInfo>
      <BulletImgContainer>
        <BulletImg src={bullet4} alt="bullet-4" />
      </BulletImgContainer>
    </Bullet>
    <Bullet>
      <BulletInfo>
        <BulletName>{bullets[4].name}</BulletName>
        <BulletCaliber>{bullets[4].caliber}</BulletCaliber>
      </BulletInfo>
      <BulletImgContainer>
        <BulletImg src={bullet5} alt="bullet-5" />
      </BulletImgContainer>
    </Bullet>
  </Container>
);

export default BulletsInfo;
