import React from 'react';
import LeftColContent from 'src/components/desktop/leftColContent/leftColContent';
import BackgroundSpecial from 'src/components/desktop/backgroundSpecial/backgroundSpecial';
import { ParallaxMordo, Mordo } from 'src/components/desktop/parallax/ParallaxMordo.jsx';
import { SlideLarge } from '../../_styles';

const Slide = ({ query }) => (
  <SlideLarge>
    <ParallaxMordo>
      <Mordo
        mainscene
        move={{ x: 10, y: 10 }}
        background={{ color: 'rgba(19,35,46, 0.25)' }}
      >
        <BackgroundSpecial responsive />
      </Mordo>

      <Mordo
        scene={2}
        move={{ x: -5, y: -5 }}
      >
        <LeftColContent body={query.mdx.body} />
      </Mordo>
    </ParallaxMordo>
  </SlideLarge>
);


export default Slide;
