import React from 'react';
import { useInView } from 'react-intersection-observer';


import {
  Wrapper,
  BackgroundImg,
  BorderImg,
  ColorImg,
} from './styles';

import Legend from './Legend';

const WarGlobalRangeAnimation = ({ body, query }) => {
  const [ref, inView] = useInView({
    threshold: 1,
  });


  return (
    <Wrapper ref={ref}>
      <BackgroundImg src={query.background.childImageSharp.fluid.src} visible={inView} alt="map" />
      <ColorImg src={query.color.childImageSharp.fluid.src} visible={inView} alt="map" />
      <BorderImg src={query.border.childImageSharp.fluid.src} visible={inView} alt="map" />
      <Legend body={body} visible={inView} />
    </Wrapper>
  );
};

export default WarGlobalRangeAnimation;
