import styled, { keyframes } from 'styled-components';
import texture from 'images/_shared/pionoise.png';
import textureGrey from 'images/_shared/pionoisedarkgrey.png';
import tekstura from 'images/_shared/tekstura.jpg';

const grainAnimation = keyframes`
  0%, 100% { transform:translate3d(0, 0, 0) }
  10% { transform:translate3d(-5%, -10%, 0) }
  20% { transform:translate3d(-15%, 5%, 0) }
  30% { transform:translate3d(7%, -25%, 0) }
  40% { transform:translate3d(-5%, 25%, 0) }
  50% { transform:translate3d(-15%, 10%, 0) }
  60% { transform:translate3d(15%, 0%, 0) }
  70% { transform:translate3d(0%, 15%, 0) }
  80% { transform:translate3d(3%, 35%, 0) }
  90% { transform:translate3d(-10%, 10%, 0) }
`;

export const Noise = styled.div`
    z-index: ${({ scene }) => scene * 10 || 0};
    position: absolute;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    pointer-events: none;
    &::after {
        content: '';
        background-image: url(${texture || ''});
        width: 400%;
        height: 400%;
        opacity: 0.6;
        position: absolute;
        animation: ${grainAnimation} 8s steps(10) infinite;
        animation-delay: 1s;
        /* will-change: transform; */
        left: -150%;
        top: -150%;
    }
`;

export const NoiseGrey = styled.div`
    z-index: ${({ scene }) => scene * 10 || 0};
    position: absolute;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    &::after {
        content: '';
        background-image: url(${textureGrey || ''});
        width: 400%;
        height: 400%;
        opacity: 0.6;
        position: absolute;
        animation: ${grainAnimation} 8s steps(10) infinite;
        animation-delay: 1s;
        /* will-change: transform; */
        left: -150%;
        top: -150%;
    }
`;

export const Tekstura = styled.div`
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: ${({ scene }) => scene * 10 || 0};
    width: 100%;
    height: 100%;
    background-image: url(${tekstura || ''});
    opacity: 0.1
`;
