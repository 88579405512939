import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Quote from 'src/components/desktop/quote/quote';
import { NoiseOverlay } from 'src/components/desktop/noiseOverlay/noiseOverlay';
import { ParallaxMordo, Mordo } from 'src/components/desktop/parallax/ParallaxMordo.jsx';
import { SlideLarge } from '../../_styles';


const Slide = ({ query }) => {
  const { author, authorInfo } = query.quote1Text.edges[0].node.frontmatter;
  const { body } = query.quote1Text.edges[0].node;

  const assetQuery = useStaticQuery(graphql`
  query {
    mainscene: file(relativePath: { eq: "chapter_1/sub_1/parallax/cytat_1.jpg"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
    scenelayer1: file(relativePath: { eq: "chapter_1/sub_1/parallax/cytat_2.png"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
    scenelayer2: file(relativePath: { eq: "chapter_1/sub_1/parallax/cytat_3.png"}) {
      childImageSharp {
          fluid(maxWidth: 894) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  }
  }
  `);

  return (
    <SlideLarge>
      <ParallaxMordo>
        <Mordo
          mainscene
          background={{ image: assetQuery.mainscene.childImageSharp.fluid }}
        />
        <Mordo
          scenelayer
          scene={1}
          move={{ x: 10, y: 20 }}
          background={{ image: assetQuery.scenelayer1.childImageSharp.fluid }}
        />
        <Mordo
          scenelayer={{ size: '50%', fromLeft: '25%' }}
          scene={2}
          move={{ x: -5, y: -20 }}
          background={{ image: assetQuery.scenelayer2.childImageSharp.fluid }}
        />
        <NoiseOverlay scene={3} />
        <Mordo
          scenelayer
          scene={4}
          move={{ x: -10, y: -20 }}
          background={{ color: 'rgba(19,35,46, 0.25)' }}
        />
        <Mordo
          flexi
          scene={5}
          move={{ x: -20, y: -10 }}
        >
          <Quote fontColor="white" quoteText={body} quoteAuthor={author} quoteAuthorRole={authorInfo} />
        </Mordo>
      </ParallaxMordo>
    </SlideLarge>

  );
};


export default Slide;
