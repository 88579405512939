import React, { useState, useEffect, useRef } from 'react';
import part1mp4 from 'src/assets/videos/chapter_1/sub_1/1-1-1-1.mp4';
import part1webm from 'src/assets/videos/chapter_1/sub_1/1-1-1-1.webm';
import part2mp4 from 'src/assets/videos/chapter_1/sub_1/1-1-1-2.mp4';
import part2webm from 'src/assets/videos/chapter_1/sub_1/1-1-1-2.webm';
import { AnimationContainer, VideoMagic } from './styles';

const BackgroundSpecial = ({
  children, responsive,
}) => {
  const [indexPlaying, setIndexPlaying] = useState(1);
  const ref = useRef(null);
  useEffect(() => {
    ref.current.onended = () => {
      setIndexPlaying(2);
    };
  }, []);
  return (
    <AnimationContainer responsive={responsive}>
      <VideoMagic autoPlay playsInline muted ref={ref} visible={indexPlaying === 1}>
        <source src={part1webm} type="video/webm" />
        <source src={part1mp4} type="video/mp4" />
      </VideoMagic>
      <VideoMagic autoPlay playsInline muted loop visible={indexPlaying === 2}>
        <source src={part2webm} type="video/webm" />
        <source src={part2mp4} type="video/mp4" />
      </VideoMagic>
      {children}
    </AnimationContainer>
  );
};

export default BackgroundSpecial;
