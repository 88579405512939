import styled, { css, keyframes } from 'styled-components';
import { fonts, colors } from '../../../../../globals';

const slideUp = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0,70px,0);
  }
  to {
    opacity: 1;
    transform: translate3d(0,0,0);
  }
`;

const animationTop = ({ visible }) => {
  if (visible) {
    return css`
      ${slideUp} 0.8s cubic-bezier(0.78, 0, 0.235, 1) 200ms forwards
    `;
  }
  return null;
};
const animationBottom = ({ visible }) => {
  if (visible) {
    return css`
      ${slideUp} 0.8s cubic-bezier(0.78, 0, 0.235, 1) 400ms forwards
    `;
  }
  return null;
};

export const Wrapper = styled.div`
  padding-top: 115px;
  width: 80%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  @media (max-width: 1600px) {
    padding-top: 85px;
    width: 90%;
  }
`

export const Header = styled.div`
  h2 {
      ${{ ...fonts.headline_2 }}
      ${(props) => (props.dark ? { ...colors.dark } : { ...colors.light })}
      margin-bottom: 3.18rem;
      opacity: 0;
      transform:translate3d(0,60px,0);
      animation: ${animationTop};
  }

  p {
    display: none;
  }
`

export const Content = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;


  h2 {
    display: none;
  }

  p {
    display: block;
    ${{ ...fonts.paragraf }}
    font-size: 0.9rem;
    ${(props) => (props.dark ? { ...colors.dark } : { ...colors.light })}
    max-width: 23rem;
    opacity: 0;
    animation: ${animationBottom};
    @media (max-width: 1600px) {
      max-width: 25rem;
      font-size: 0.85rem;
  }
  }
`

export const ColLeft = styled.div`
  .col-right {
    display: none;
  }
`
export const ColRight = styled.div`
  .col-left {
    display: none;
  }
`