import React from 'react';
import TwoColContent from 'src/components/desktop/twoColContent/twoColContent';
import { SlideMedium } from '../../_styles';
import { graphql, useStaticQuery } from 'gatsby';

const Slide = ({ query }) => {
  const assetQuery = useStaticQuery(graphql`
  query {
    file(relativePath: { eq: "chapter_1/sub_1/9.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`);


  return (
    <SlideMedium black>
      <TwoColContent
        photo={assetQuery.file.childImageSharp.fluid}
        body={query.mdx.body}
      />
    </SlideMedium>
  )
};

export default Slide;
