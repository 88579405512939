import React, { useEffect, useRef } from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import { useInView } from 'react-intersection-observer';
import {
  WrapperBackground, Wrapper, WrapperText, ObserverRef,
} from './styles';

const SignedPhoto = ({
  photo,
  text,
  colorText = '#ffffff',
  widthText = 80,
  alt,
  fullScreen = false,
  observer = '100%'
}) => {
  const [ref, inView] = useInView({
    threshold: 0.9,
    rootMargin: '0px -12% 0px -12%'
  });

  return (
    <>
      <Wrapper>
        <ObserverRef ref={ref} observer={observer} />
        <WrapperBackground fixed={photo} alt={alt} />
        {text && (
          <WrapperText
            colorText={colorText}
            widthText={widthText}
            visible={inView}
            fullScreen={fullScreen}
          >
            <MDXProvider components={{
              p: (props) => <p {...props} tabIndex="0" />,
            }}
            >
              <MDXRenderer>{text}</MDXRenderer>
            </MDXProvider>
          </WrapperText>
        )}
      </Wrapper>
    </>
  );
};

export default SignedPhoto;
